import React, { useState, useEffect } from 'react';
import axios from 'axios';

function GallarySocial() {
  const [activities, setActivities] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  useEffect(() => {
    fetchActivities();
  }, []);

  const fetchActivities = async () => {
    try {
      const response = await axios.get('https://samvedanafoundation.com/exe_files/list_social_news.php');
      
      if (response.data && response.data.length > 0) {
        const sortedActivities = response.data.sort((a, b) => new Date(b.Date) - new Date(a.Date));
        setActivities(sortedActivities);
      } else {
        setError('No activities found');
      }
      
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage(null);
  };

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-500">Error: {error}</div>;
  }

  return (
    <div className="container mx-auto py-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {activities.map((activity, index) => (
          <div key={index} className="max-w-xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden mb-8 transition-transform transform hover:scale-105">
            <div className="px-6 py-4">
              <h1 className="text-2xl font-bold text-gray-800 text-center">{activity.Head}</h1>
              <div className="flex items-center justify-between mt-4">
                <span className="text-sm font-bold text-gray-600">
                  {activity.Date}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{activity.Place}
                </span>
              </div>
              <img 
                src={`https://samvedanafoundation.com/exe_files/images/activity_images/${activity.PhotoPath}`} 
                alt={activity.ShortDescription || 'Activity'} 
                className="w-full h-auto object-cover object-center cursor-pointer"
                onClick={() => openModal(`https://samvedanafoundation.com/exe_files/images/activity_images/${activity.PhotoPath}`)}
              />
              <div className="mt-4">
                <h4 className="text-xl font-semibold text-gray-700">{activity.ShortDescription}</h4>
                <p className="mt-2 text-gray-600">{activity.Description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-90 flex items-center justify-center z-50">
          <div className="relative">
            <img
              src={currentImage}
              alt="Activity Image"
              className="max-w-full h-auto rounded-lg shadow-lg transform transition-transform duration-500 ease-in-out scale-100 hover:scale-105"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-red-500 text-7xl font-bold hover:text-red-800 transition-all"
              aria-label="Close Modal"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default GallarySocial;
