import React, { useState, useEffect } from 'react';

function BodyManasvardhan() {
  const [activity, setActivity] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    fetchActivity();
  }, []);

  const fetchActivity = async () => {
    try {
      const response = await fetch('https://samvedanafoundation.com/exe_files/latest_activity_manasvardhan.php');
      if (!response.ok) {
        throw new Error('Failed to fetch activity');
      }
      const data = await response.json();

      if (data.length > 0) {
        setActivity(data[0]); // Set the first object in the array as the activity
      } else {
        throw new Error('No activity data found');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false); // End loading once data is fetched
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        <p className="text-gray-600 text-lg">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <p className="text-red-600 text-lg">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 py-10">
      {activity ? (
        <div className="bg-white rounded-xl shadow-lg p-6 w-full max-w-7xl ">
          {/* Header Section */}
          <h1 className="text-3xl font-bold text-center text-blue-900 mb-6 capitalize">
            {activity.Head}
          </h1>

          {/* Image and Date Section */}
          <div className="mt-6 relative">
            <img 
              src={`https://samvedanafoundation.com/exe_files/images/activity_images/${activity.PhotoPath}`} 
              alt={activity.ShortDescription || 'Activity'} 
              className="w-full h-auto object-cover rounded-lg shadow-md"
            />
            <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black to-transparent p-4 rounded-b-lg">
              <span className="text-white text-sm font-semibold">
                {activity.Date} - {activity.Place}
              </span>
            </div>
          </div>

          {/* Content Section */}
          <div className="content-div space-y-4 py-4">
            <h4 className="text-xl font-semibold text-gray-700">{activity.ShortDescription}</h4>
            <p className="text-gray-600 leading-relaxed">{activity.Description}</p>
          </div>
        </div>
      ) : (
        <p className="text-gray-600 text-lg">No activity data available.</p>
      )}
    </div>
  );
}

export default BodyManasvardhan;
