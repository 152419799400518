import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

function AddProfessionalAwards() {
    const [formData, setFormData] = useState({
        head: "",
        description: "",
        date: "",
        place: "",
        images: [null, null, null] // Array to store 3 images
    });
    const [isEditing, setIsEditing] = useState(false);
    const [imagePreviews, setImagePreviews] = useState([null, null, null]); // Previews for 3 images
    const navigate = useNavigate();
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const editId = query.get('editId');

    useEffect(() => {
        if (editId) {
            setIsEditing(true);
            fetchAwardDetails(editId);
        }
    }, [editId]);

    const fetchAwardDetails = async (id) => {
        try {
            const response = await axios.get(`https://samvedanafoundation.com/exe_files/get_professional_award.php?id=${id}`);
            if (response.data) {
                setFormData({
                    head: response.data.Head,
                    description: response.data.Description,
                    date: response.data.Date,
                    place: response.data.Place,
                    images: [null, null, null] 
                });
                setImagePreviews([
                    `https://samvedanafoundation.com/exe_files/images/activity_images/${response.data.PhotoPath1}`,
                    `https://samvedanafoundation.com/exe_files/images/activity_images/${response.data.PhotoPath2}`,
                    `https://samvedanafoundation.com/exe_files/images/activity_images/${response.data.PhotoPath3}`
                ]);
            }
        } catch (error) {
            alert("Failed to fetch award details. Please try again.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e, index) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const updatedImages = [...formData.images];
            updatedImages[index] = file;
            setFormData({ ...formData, images: updatedImages });

            const updatedPreviews = [...imagePreviews];
            updatedPreviews[index] = URL.createObjectURL(file);
            setImagePreviews(updatedPreviews);
        } else {
            alert("Please upload an image file.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.head || !formData.description || !formData.date || !formData.place) {
            alert("Please fill in all required fields");
            return;
        }

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('head', formData.head);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('date', formData.date);
            formDataToSend.append('place', formData.place);

            formData.images.forEach((image, index) => {
                if (image) {
                    formDataToSend.append(`image${index + 1}`, image);
                }
            });

            const url = isEditing
                ? 'https://samvedanafoundation.com/exe_files/update_professional_award.php'
                : 'https://samvedanafoundation.com/exe_files/add_professional_award.php';

            const response = await axios.post(url, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            alert("Form submitted successfully");
            console.log(response,"successfully")
            navigate('/'); 
        } catch (error) {
            alert("Failed to submit the form. Please try again.");
        }
    };

    return (
        <div className=''>
            <h1 className='text-center text-3xl font-bold'>Professional Awards</h1>

            <div className="heading text-center font-bold text-2xl m-5 text-gray-800">{isEditing ? "Edit Award" : "New Award Post"}</div>
            <form className="editor mx-auto w-10/12 flex flex-col text-gray-800 border border-gray-300 p-4 shadow-lg max-w-7xl" onSubmit={handleSubmit}>
                <input
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none"
                    placeholder="Activity Head"
                    type="text"
                    name="head"
                    value={formData.head}
                    onChange={handleChange}
                />
                <textarea
                    className="description bg-gray-100 sec p-3 mb-4 h-60 border border-gray-300 outline-none"
                    placeholder="Describe everything about this post here"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                ></textarea>
                {formData.images.map((_, index) => (
                    <div key={index} className="mb-4">
                        <input
                            type="file"
                            className="title bg-gray-100 border border-gray-300 p-2 mb-2 outline-none"
                            accept="image/*"
                            onChange={(e) => handleImageChange(e, index)}
                        />
                        {imagePreviews[index] && (
                            <img 
                                src={imagePreviews[index]} 
                                alt={`Preview ${index + 1}`} 
                                style={{ maxWidth: '200px', maxHeight: '200px', objectFit: 'cover' }}
                                className='flex' 
                            />
                        )}
                    </div>
                ))}
                <input
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none"
                    placeholder="Select Date"
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                />
                <input
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none"
                    placeholder="Activity Place"
                    type="text"
                    name="place"
                    value={formData.place}
                    onChange={handleChange}
                />
                <div className="buttons flex">
                    <button
                        type="submit"
                        className="btn border border-indigo-500 p-1 px-4 font-semibold cursor-pointer text-gray-200 ml-2 bg-indigo-500"
                    >
                        {isEditing ? "Update" : "Post"}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default AddProfessionalAwards;