import React, { useState, useEffect } from 'react';
import axios from 'axios';

function GallaryNews() {
  const [activities, setActivities] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  useEffect(() => {
    fetchActivities();
  }, []);

  const fetchActivities = async () => {
    try {
      const response = await axios.get('https://samvedanafoundation.com/exe_files/list_newspaper.php');
      if (response.data && response.data.length > 0) {
        // Sort activities by date in descending order (newest first)
        const sortedActivities = response.data.sort((a, b) => new Date(b.Date) - new Date(a.Date));
        setActivities(sortedActivities);
      } else {
        setError('No activities found');
      }
    } catch (error) {
      setError('Failed to load activities. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const openModal = (imagePath) => {
    setCurrentImage(imagePath);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage('');
  };

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-500">Error: {error}</div>;
  }

  return (
    <div className="">
    <div>
      <h1 className="text-4xl font-extrabold text-center mb-8 text-gray-800">Latest News & Activities</h1>
      <div className="container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mx-auto">
        {activities.map((activity, index) => (
          <div 
            key={index} 
            className="relative group max-w-xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105">
            <img 
              src={`https://samvedanafoundation.com/exe_files/images/activity_images/${activity.PhotoPath}`} 
              alt={activity.ShortDescription || 'Activity'} 
              className="w-full h-auto object-cover object-center cursor-pointer" 
              onClick={() => openModal(`https://samvedanafoundation.com/exe_files/images/activity_images/${activity.PhotoPath}`)}
            />
            <div className="p-6">
              <h2 className="text-2xl font-bold text-gray-800 mb-2 text-center">{activity.Head}</h2>
              <div className="flex justify-between items-center mb-4">
                <p className="text-sm font-semibold text-gray-600">{activity.Newspaper}</p>
                <p className="text-sm font-semibold text-gray-600">{activity.Date} | {activity.Place}</p>
              </div>
              <h4 className="text-lg font-medium text-gray-700 mb-2">{activity.ShortDescription}</h4>
              <p className="text-gray-600 text-sm line-clamp-3">{activity.Description}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for image preview */}
      {isModalOpen && (
  <div className="fixed inset-0 bg-gray-900 bg-opacity-90 flex items-center justify-center z-50">
    <div className="relative">
      <img
        src={currentImage}
        alt="Activity Image"
        className="max-w-full max-h-[90vh] rounded-lg shadow-lg transform transition-transform duration-500 ease-in-out scale-100 hover:scale-105"
      />
      <button
        onClick={closeModal}
        className="absolute top-4 right-4 text-red-500 text-7xl font-bold hover:text-red-800 transition-all"
        aria-label="Close Modal"
      >
        &times;
      </button>
    </div>
  </div>
)}

    </div>
    </div>
  );
}

export default GallaryNews;
