import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function ListProfessionalAwards() {
    const [professionalAwardsList, setProfessionalAwardsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        fetchProfessionalAwardsList();
    }, []);

    const fetchProfessionalAwardsList = async () => {
        try {
            const response = await axios.get('https://samvedanafoundation.com/exe_files/list_professional_award.php');
            if (response.data && Array.isArray(response.data)) {
                setProfessionalAwardsList(response.data);
            } else {
                throw new Error("Unexpected response format");
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const deleteProfessionalAward = async (index) => {
        try {
            const award = professionalAwardsList[index];
            if (!award || !award.ActivityId) {
                throw new Error("Award or ActivityId not found");
            }
    
            const response = await axios.post('https://samvedanafoundation.com/exe_files/delete_professional_award.php', {
                Id: award.ActivityId
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            if (response.data.response === "D") {
                setProfessionalAwardsList(professionalAwardsList.filter((_, i) => i !== index));
                alert("Professional Award deleted successfully");
            } else {
                alert("Error: " + response.data.msg || "Unknown error");
            }
        } catch (error) {
            alert("Error deleting Professional Award. Please try again.");
        }
    };

    const handleEditClick = (index) => {
        const award = professionalAwardsList[index];
        if (award && award.ActivityId) {
            navigate(`/add-professional-award?editId=${award.ActivityId}`);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <div className="text-gray-900">
                <div className="p-4 flex">
                    <h1 className="text-3xl">List Professional Awards</h1>
                </div>
                <div className="px-3 py-4 flex justify-center">
                    <table className="w-full text-md bg-white shadow-md rounded mb-4">
                        <thead>
                            <tr>
                                <th className="text-left p-3 px-5">Head</th>
                                <th className="text-left p-3 px-5">Description</th>
                                <th className="text-left p-3 px-5">Date</th>
                                <th className="text-left p-3 px-5">Place</th>
                                <th className="text-left p-3 px-5">Image</th>
                                <th className="text-left p-3 px-5">Edit/Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {professionalAwardsList.map((award, index) => (
                                <tr key={award.ActivityId} className={index % 2 === 0 ? 'border-b hover:bg-orange-100 bg-gray-100' : 'border-b hover:bg-orange-100'}>
                                    <td className="p-3 px-5">{award.Head}</td>
                                    <td className="p-3 px-5">{award.Description}</td>
                                    <td className="p-3 px-5">{award.Date}</td>
                                    <td className="p-3 px-5">{award.Place}</td>
                                    <td className="p-3 px-5">
                                        <img 
                                            src={award.PhotoPath 
                                                ? `https://samvedanafoundation.com/exe_files/images/activity_images/${award.PhotoPath}` 
                                                : '/default-image.jpg'} 
                                            alt={award.Head} 
                                            style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }} 
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = '/default-image.jpg'; // fallback image if error occurs
                                            }}
                                        />
                                    </td>
                                    <td className="p-3 px-5 flex justify-end">
                                        <button 
                                            type="button" 
                                            onClick={() => handleEditClick(index)} // Add onClick event for edit button
                                            className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                        >
                                            Edit
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => deleteProfessionalAward(index)}
                                            className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ListProfessionalAwards;
